// ~/composables/useContentHeight.js
import { ref } from 'vue';

// This is a shared reference for content height
const contentHeight = ref(0);

export function setContentHeight(height) {
  contentHeight.value = height;
}

export function useContentHeight() {
  return contentHeight;
}
